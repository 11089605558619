.sidebar {

  &-top {
    height: 60px;
    padding-left: 25px;
    padding-top: 17px;
  }

  &-title {
    color: #8995a0;
    display: block;
    font-weight: 300;

    & h2 {
      margin: 0;
    }

    &:active,
    &:hover {
      color: lighten(#8995a0, 10%);

    }
  }

}

.mini-navbar .sidebar-top {
  display: none;
}

body {
  background-color: #f3f3f4;

  &.mini-navbar .nav-header {
    background: none;
  }

  &.full-height-layout #wrapper,
  &.full-height-layout #page-wrapper {
    height: auto;
    width: 100%;

    @media (min-width: 768px){
        max-width: 100%;
        width: auto;
    }
  }
}


.footer {
  background: transparent;
  border: 0;
}

.navbar {
  &-minimalize {
    @media (min-width: 768px){
        display: none;
    }
  }

  &-static-side {
    background-color: #203142;
  }

  &-wrapper-custom &-static-side {
    position: absolute !important;
  }
}

.nav {
  &-header {
    padding: 25px;
  }

  &-third-level > li.active {
    border-left: 0;
  }
}

.navbar-right {
  float: right;
}

.user-logo {
  display: block;
  height: 100px;
  margin-bottom: 3rem;

  .fa {
    margin: 0 4px;
  }

  &__main-logo {
    border: 2px solid #8095a8;
    border-radius: 4px;
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100px;
    background: #fff;
    display: inline-block;
  }

  &__sub-logo {
    border: 2px solid white;
    background-color: #fff;
    background: white;
    border-radius: 4px;
    height: auto;
    width: auto;
    max-height: 50px;
    max-width: 100px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: inline-block;
  }
}

.entec-logo {
  margin: 1rem 0;
  width: 90px;

  @media (min-width: 768px) {
    width: 100px;
    margin: 1rem 0 1rem 3px;
  }
}

.impersonating {
  color: #DFE4ED;
}


.body-small .navbar-minimalize {
  display: inline-block;
}

#wrapper {
    background-color: #203142;
}

[data-menu-active] {
  background: #1b2938;

  > a {
    color: #fff;
  }
}

// Helpers
.display-inline {
  display: inline-block;
}
