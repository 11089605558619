.pie {

  svg {
    width: 30px;
    height: 30px;
    transform: rotate(-90deg);
    background: #d7d7d7;
    border-radius: 50%;
  }

  circle {
    fill: #d7d7d7;
    stroke: #1ab394;
    stroke-width: 32;
  }
}