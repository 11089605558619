/* s3direct style overrides
*/

.ibox {
  .s3direct {
    float: none;
    padding-top: 7px;

    .progress {
      float: left
    }

    .file-remove {
      @extend .btn;
      text-transform: none;
      background-color: $red;
      border-color: $red;
      color: #FFFFFF;
      padding: 6px 12px;
    }

    .file-link {
      margin-right: 5px;
    }

    .file-link,
    .file-remove,
    .file-input {
      display: none;
      float: none;
      clear: left;
    }

    .file-input {
      font-size: 85%;
      font-style: italic;
      color: #88889C;
    }
  }

  .progress-striped .bar {
    background-color: #1ab394;
  }

  .s3direct.progress-active .progress,
  .s3direct.link-active .file-link,
  .s3direct.link-active .file-remove,
  .s3direct.form-active .file-input {
    font-family: inherit;
    display: inline-block;
  }
}