/* SEARCH PAGE */

.search-form {
  margin-top: 10px;
}

.search-result h3 {
  margin-bottom: 0;
  color: #1E0FBE;
}

.search-result .search-link {
  color: #006621;
}

.search-result p {
  font-size: 12px;
  margin-top: 5px;
}

/* CONTACTS */

.contact-box {
  background-color: #ffffff;
  border: 1px solid $border-color;
  padding: 20px;
  margin-bottom: 20px;
}

.contact-box > a {
  color: inherit;
}

.contact-box.center-version {

  border: 1px solid $border-color;
  padding: 0;
}

.contact-box.center-version > a {
  display: block;
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
}

.contact-box.center-version > a img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.contact-box.center-version address {
  margin-bottom: 0;
}

.contact-box .contact-box-footer {
  text-align: center;
  background-color: #ffffff;
  border-top: 1px solid $border-color;
  padding: 15px 20px;
}

/* ERROR & LOGIN & LOCKSCREEN */

.middle-box {
  max-width: 400px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 40px;
}

.lockscreen.middle-box {
  width: 200px;
  padding-top: 110px;
}

.loginscreen.middle-box {
  width: 300px;
}

.loginColumns {
  max-width: 800px;
  margin: 0 auto;
  padding: 100px 20px 20px 20px;
}

.passwordBox {
  max-width: 460px;
  margin: 0 auto;
  padding: 100px 20px 20px 20px;
}

.logo-name {
  color: #e6e6e6;
  font-size: 180px;
  font-weight: 800;
  letter-spacing: -10px;
  margin-bottom: 0;
}

.middle-box h1 {
  font-size: 170px;
}

.wrapper .middle-box {
  margin-top: 140px;
}

.lock-word {
  z-index: 10;
  position: absolute;
  top: 110px;
  left: 50%;
  margin-left: -470px;
}

.lock-word span {
  font-size: 100px;
  font-weight: 600;
  color: #e9e9e9;
  display: inline-block;
}

.lock-word .first-word {
  margin-right: 160px;
}

/* DASHBOARD */

.dashboard-header {
  border-top: 0;
  padding: 20px 20px 20px 20px;
}

.dashboard-header h2 {
  margin-top: 10px;
  font-size: 26px;
}

.fist-item {
  border-top: none !important;
}

.statistic-box {
  margin-top: 40px;

}

.dashboard-header .list-group-item span.label {
  margin-right: 10px;
}

.list-group.clear-list .list-group-item {
  border-top: 1px solid $border-color;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  padding: 10px 0;
}

ul.clear-list:first-child {
  border-top: none !important;
}

/* Intimeline */

.timeline-item .date i {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  width: 30px;
  text-align: center;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  border-left: 1px solid $border-color;
  background: #f8f8f8;
}

.timeline-item .date {
  text-align: right;
  width: 110px;
  position: relative;
  padding-top: 30px;
}

.timeline-item .content {
  border-left: 1px solid $border-color;
  border-top: 1px solid $border-color;
  padding-top: 10px;
  min-height: 100px;
}

.timeline-item .content:hover {
  background: #f6f6f6;
}

/* PROFILE */

.profile-content {
  border-top: none !important;
}

.profile-stats {
  margin-right: 10px;
}

.profile-image {
  width: 120px;
  float: left;
}

.profile-image img {
  width: 96px;
  height: 96px;
}

.profile-info {
  margin-left: 120px;
}

.feed-activity-list .feed-element {
  border-bottom: 1px solid $border-color;
}

.feed-element:first-child {
  margin-top: 0;
}

.feed-element {
  padding-bottom: 15px;
}

.feed-element, .feed-element .media {
  margin-top: 15px;
}

.feed-element, .media-body {
  overflow: hidden;
}

.feed-element > .pull-left {
  margin-right: 10px;
}

.feed-element img.img-circle, .dropdown-messages-box img.img-circle {
  width: 38px;
  height: 38px;
}

.feed-element .well {
  border: 1px solid $border-color;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 11px;
  line-height: 16px;
}

.feed-element .actions {
  margin-top: 10px;
}

.feed-element .photos {
  margin: 10px 0;

}

.feed-photo {
  max-height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
}

.file-list li {
  padding: 5px 10px;
  font-size: 11px;
  border-radius: 2px;
  border: 1px solid $border-color;
  margin-bottom: 5px;

}

.file-list li a {
  color: inherit;
}

.file-list li a:hover {
  color: $navy;
}

.user-friends img {
  width: 42px;
  height: 42px;
  margin-bottom: 5px;
  margin-right: 5px;

}

/* PROJECTS */
.project-people, .project-actions {
  text-align: right;
  vertical-align: middle;
}

dd.project-people {
  text-align: left;
  margin-top: 5px;
}

.project-people img {
  width: 32px;
  height: 32px;
}

.project-title a {
  font-size: 14px;
  color: $text-color;
  font-weight: 600;
}

.project-list table tr td {
  border-top: none;
  border-bottom: 1px solid $border-color;
  padding: 15px 10px;
  vertical-align: middle;
}

.project-manager .tag-list li a {
  font-size: 10px;
  background-color: white;
  padding: 5px 12px;
  color: inherit;
  border-radius: 2px;
  border: 1px solid $border-color;
  margin-right: 5px;
  margin-top: 5px;
  display: block;
}

.project-files li a {
  font-size: 11px;
  color: $text-color;
  margin-left: 10px;
  line-height: 22px;
}

/* FAQ */

.faq-item {
  padding: 20px;
  margin-bottom: 2px;
  background: #fff;
}

.faq-question {
  font-size: 18px;
  font-weight: 600;
  color: $navy;
  display: block;
}

.faq-question:hover {
  color: darken($navy, 5%);
}

.faq-answer {
  margin-top: 10px;
  background: $gray;
  border: 1px solid $border-color;
  border-radius: 3px;
  padding: 15px;
}

.faq-item .tag-item {
  background: $gray;
  padding: 2px 6px;
  font-size: 10px;
  text-transform: uppercase;
}

/* jsTree */
.jstree-open > .jstree-anchor > .fa-folder:before {
  content: "\f07c";
}

.jstree-default .jstree-icon.none {
  width: 0;
}

/* CLIENTS */

.clients-list {
  margin-top: 20px;
}

.clients-list .tab-pane {
  position: relative;
  height: 600px;
}

.client-detail {
  position: relative;
  height: 620px;
}

.clients-list table tr td {
  height: 46px;
  vertical-align: middle;
  border: none;
}

.client-link {
  font-weight: 600;
  color: inherit;
}

.client-link:hover {
  color: inherit;
}

.client-avatar {
  width: 42px;
}

.client-avatar img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.contact-type {
  width: 20px;
  color: lighten($text-color, 35%);
}

.client-status {
  text-align: left;
}

.client-detail .vertical-timeline-content p {
  margin: 0;
}

.client-detail .vertical-timeline-icon.gray-bg {
  color: lighten($text-color, 25px);
}

.clients-list {
  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    border-bottom: 1px solid #fff;
  }
}


/* ISSUE TRACKER */

.issue-tracker .btn-link {
  color: $navy;
}

table.issue-tracker tbody tr td {
  vertical-align: middle;
  height: 50px;
}

.issue-info {
  width: 50%;
}

.issue-info a {
  font-weight: 600;
  color: $text-color;
}

.issue-info small {
  display: block;
}

/* TEAMS */
.team-members {
  margin: 10px 0;
}

.team-members img.img-circle {
  width: 42px;
  height: 42px;
  margin-bottom: 5px;
}

/* AGILE BOARD */

.sortable-list {
  padding: 10px 0;
}

.agile-list {
  list-style: none;
  margin: 0;
}

.agile-list li {

  background: #FAFAFB;
  border: 1px solid #e7eaec;
  margin: 0 0 10px 0;
  padding: 10px;
  border-radius: 2px;
}

.agile-list li:hover {
  cursor: pointer;
  background: #fff;
}

.agile-list li.warning-element {
  border-left: 3px solid $yellow;
}

.agile-list li.danger-element {
  border-left: 3px solid $red;
}

.agile-list li.info-element {
  border-left: 3px solid $blue;
}

.agile-list li.success-element {
  border-left: 3px solid $navy;
}

.agile-detail {
  margin-top: 5px;
  font-size: 12px;
}

/* DIFF */
ins {
  background-color: #c6ffc6;
  text-decoration: none;
}

del {
  background-color: #ffc6c6;
}

/* Social feed */
.social-feed-separated .social-feed-box {
  margin-left: 62px;
}

.social-feed-separated .social-avatar {
  float: left;
  padding: 0;
}

.social-feed-separated .social-avatar img {
  width: 52px;
  height: 52px;
  border: 1px solid #e7eaec;
}

.social-feed-separated .social-feed-box .social-avatar {
  padding: 15px 15px 0 15px;
  float: none;
}

.social-feed-box {
  /*padding: 15px;*/
  border: 1px solid #e7eaec;
  background: #fff;
  margin-bottom: 15px;
}

.article .social-feed-box {
  margin-bottom: 0;
  border-bottom: none;
}

.article .social-feed-box:last-child {
  margin-bottom: 0;
  border-bottom: 1px solid $border-color;
}

.article .social-feed-box p {
  font-size: 13px;
  line-height: 18px;
}

.social-action {
  margin: 15px;
}

.social-avatar {
  padding: 15px 15px 0 15px;
}

.social-comment .social-comment {
  margin-left: 45px;
}

.social-avatar img {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.social-avatar .media-body a {
  font-size: 14px;
  display: block;
}

.social-body {
  padding: 15px;
}

.social-body img {
  margin-bottom: 10px;
}

.social-footer {
  border-top: 1px solid #e7eaec;
  padding: 10px 15px;
  background: #f9f9f9;
}

.social-footer .social-comment img {
  width: 32px;
  margin-right: 10px;
}

.social-comment:first-child {
  margin-top: 0;
}

.social-comment {
  margin-top: 15px;
}

.social-comment textarea {
  font-size: 12px;
}
