$extra-colors: (
  "wisteria": #8e44ad,
  "green-sea": #16a085,
  "emerald": #2ecc71,
  "amethyst": #9b59b6,
  "wet-asphalt": #34495e,
  "midnight-blue": #2c3e50,
  "belize-hole": #2980b9,
  "alizarin": #e74c3c,
  "pomegranate": #c0392b,
  "pumpkin": #d35400,
  "orange": #f39c12,
  "carrot": #e67e22,
  "turquoise": #1abc9c,
  "sun-flower": #f1c40f,
  "silver": #bdc3c7,
);

/* Recreate extra colors in same class style as Inspinia. */
@each $color-name, $color-value in $extra-colors {
  .#{$color-name}-bg {
    background-color: $color-value;
    color: white;
  }
}
