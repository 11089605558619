.table-one-liner {

    & > thead > tr > th,
    & > tbody > tr > th,
    & > tfoot > tr > th,
    & > thead > tr > td,
    & > tbody > tr > td,
    & > tfoot > tr > td {
        vertical-align: middle;
    }

}
