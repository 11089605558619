
// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

/* Import Inspinia variables */
@import "inspinia/variables";

/* Override Inspinia variables */
$boxed-background: none;
$nav-bg: #203142;
$nav-profile-pattern: none;
$sidebar-width: 280px;

/* Import Inspinia Bootstrap Admin files */
@import "inspinia/mixins";
@import "inspinia/typography";
@import "inspinia/navigation";
@import "inspinia/top_navigation";
@import "inspinia/buttons";
@import "inspinia/badges_labels";
@import "inspinia/elements";
@import "inspinia/sidebar";
@import "inspinia/base";
@import "inspinia/pages";
@import "inspinia/metismenu";
@import "inspinia/media";

// Clear layout on print mode
@media print {
    nav.navbar-static-side {
        display: none;
    }
    body { overflow: visible !important; }

    #page-wrapper {
        margin: 0;
    }
}

/* Wound Care Buddy Custom CSS */
@import "modules/extra_colors";
@import "modules/icons";
@import "modules/tables";
@import "modules/forms";
@import "modules/toastr";
@import "modules/nav";
@import "modules/s3direct";
@import "modules/timeline";
@import "modules/pie";
@import "modules/product_formulary";
