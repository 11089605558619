.errorlist {
	padding: 0;
	margin: 0;

  & > li > ul > li {
		&::before {
			content: '- ';
			display: inline;
		}
	}

  & > li {
		list-style: none;
	}
}

.has-error .mce-tinymce {
  border: 2px solid $red;
}

.text-danger {
	clear: both;
}

.form-horizontal {

  input[type=checkbox],
  input[type=radio] {
    margin: 4px 4px 10px 0;
  }

}

.form-fields {
  text-align: left;

  @media (min-width: 768px){
      text-align: right;
  }
}