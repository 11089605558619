/* Toastr style overrides
   Note that Toastr and Inspinia both provide the base styles.
*/

#toast-container.toast-bottom-right {
    bottom: 46px;
    right: 25px;
    cursor: default;
}

#toast-container > .toast:before {
    line-height: 18px;
}
